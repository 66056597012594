<template>
  <b-row>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="CpuIcon"
        :statistic="statistic.accountActive"
        statistic-title="Tổng tài khoản hoạt động"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="ServerIcon"
        color="success"
        :statistic="statistic.accountWaning"
        statistic-title="Tổng tài khoản gặp cảnh báo"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="ActivityIcon"
        color="danger"
        :statistic="statistic.accountInactive"
        statistic-title="Tổng tài khoản lỗi"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        icon="AlertOctagonIcon"
        color="warning"

        :statistic="statistic.sumMoney.toLocaleString()"
        statistic-title="Tổng số dư chưa rút"
      />
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { useStatistic } from '@/views/bank/useBank'
import { onMounted } from '@vue/composition-api'

export default {
  name: 'BankStatic',
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
  },
  setup() {
    const {
      statistic,
      fetchStatistic,
    } = useStatistic()

    onMounted(() => {
      fetchStatistic().then(response => {
        statistic.value = response
      })
    })

    return {
      statistic,
      fetchStatistic,
    }
  },
}
</script>

<style scoped>

</style>
