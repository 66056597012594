<template>
  <b-modal
    ref="modal"
    title="Thêm tài khoản bank payin"
    centered
    ok-title="Thêm mới"
    size="lg"
    :hide-footer="true"
  >
    <validation-observer
      ref="createAccountForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2 ml-3 mr-3"
        @submit.prevent="createBankAccount"
      >
        <b-row cols="1">
          <b-col cols="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ngân hàng"
                vid="bankAccount.bankMasterId"
                rules="required"
              >
                <v-select
                  id="bank-master"
                  v-model="bankAccount.bankMasterId"
                  :options="bankMasterOptions"
                  :reduce="bank => bank.id"
                  label="bankName"
                  placeholder="Chọn ngân hàng"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Số tài khoản"
                vid="bankAccount.accountNumber"
                rules="required"
              >
                <b-form-input
                  id="accountNumber"
                  v-model="bankAccount.accountNumber"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Số tài khoản"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Tên chủ tài khoản"
                vid="bankAccount.name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="bankAccount.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Tên chủ tài khoản"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Tài khoản đăng nhập"
                vid="bankAccount.accountName"
              >
                <b-form-input
                  id="accountNumber"
                  v-model="bankAccount.accountName"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Tài khoản đăng nhập"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu"
                vid="bankAccount.password"
              >
                <b-form-input
                  id="password"
                  v-model="bankAccount.password"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Mật khẩu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="otp"
                vid="bankAccount.otp"
              >
                <b-form-input
                  id="otp"
                  v-model="bankAccount.otp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="OTP"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Hạn mức nhận tiền trong ngày"
                vid="bankAccount.moneyLimitPerDay"
              >
                <b-form-input
                  id="moneyLimitPerDay"
                  v-model="bankAccount.moneyLimitPerDay"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Hạn mức nhận tiền trong ngày"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Hạn mức rút tiền về tài khoản cha"
                vid="bankAccount.moneyLimitToParent"
              >
                <b-form-input
                  id="moneyLimitToParent"
                  v-model="bankAccount.moneyLimitToParent"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Hạn mức rút tiền về tài khoản cha"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Số dư hiện tại"
                vid="bankAccount.balancer"
              >
                <b-form-input
                  id="balancer"
                  v-model="bankAccount.balancer"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Số dư hiện tại"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  class="w-50"
                  :src="qrUrl || bankAccount.qrCode || require('@/assets/images/default/qr-code.svg')"
                  @click="$refs.refInputEl.$el.click()"
                />
              </b-link>
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="inputImageRenderer"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1">
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="closeModal"
            >
              {{ isUpdate ? 'Huỷ cập nhật' : 'Hủy thêm mới' }}
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              :disabled="invalid || isCreating"
              class="ml-1"
            >
              <b-spinner
                v-if="isCreating"
                small
              />
              {{ isUpdate ? 'Cập nhật' : 'Thêm mới' }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BLink,
  BImg,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import { required } from '@validations'
import Swal from 'sweetalert2'
import { useBankAccountCreate, useInputImageRenderer, useBankAccountActions } from './useBank'

export default {
  name: 'CreateAccountModal',
  components: {
    vSelect,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BLink,
    BImg,
    BFormFile,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  setup(ctx, { emit }) {
    const {
      bankMaster,
      bankMasterOptions,
      bankAccount,
      fetchBankMaster,
      addBankAccount,
    } = useBankAccountCreate()

    const {
      updateBankAccount,
    } = useBankAccountActions()

    const modal = ref(null)
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const isCreating = ref(false)
    const isUpdate = ref(false)

    const showModal = () => {
      modal.value.show()
    }
    const closeModal = () => {
      modal.value.hide()
    }

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, bankAccount)

    const initBankMasterOptions = () => {
      fetchBankMaster().then(res => {
        bankMasterOptions.value = res
      })
    }

    const updateAccount = account => {
      isUpdate.value = true
      bankAccount.value = account
      bankAccount.value.bankMasterId = account.bankMaster.id
      showModal()
    }

    const createAccount = () => {
      isUpdate.value = false
      bankAccount.value = {
        name: '',
        accountNumber: '',
        accountName: '',
        password: '',
        otp: '',
        qrCode: '',
        moneyLimitPerDay: '',
        moneyLimitToParent: '',
        balancer: '',
        bankMasterId: '',
      }
      showModal()
    }

    const qrUrl = computed(() => (bankAccount.value.qrCode && !bankAccount.value.qrCode.includes('data:image/jpeg;base64') ? `${process.env.VUE_APP_S3_URL}/${bankAccount.value.qrCode}` : null))

    const createBankAccount = () => {
      isCreating.value = true
      const formData = new FormData()
      formData.append('qrCode', refInputEl.value.files[0])
      formData.append('name', bankAccount.value.name)
      formData.append('accountNumber', bankAccount.value.accountNumber)
      formData.append('accountName', bankAccount.value.accountName)
      formData.append('password', bankAccount.value.password)
      formData.append('otp', bankAccount.value.otp)
      formData.append('moneyLimitPerDay', bankAccount.value.moneyLimitPerDay)
      formData.append('moneyLimitToParent', bankAccount.value.moneyLimitToParent)
      formData.append('balancer', bankAccount.value.balancer)
      formData.append('bankMasterId', bankAccount.value.bankMasterId)

      if (isUpdate.value) {
        updateBankAccount({ id: bankAccount.value.id, account: formData }).then(() => {
          isCreating.value = false
          closeModal()
          Swal.fire({
            icon: 'success',
            title: 'Cập nhật thành công',
            showConfirmButton: false,
            timer: 1500,
          })
        }).catch(() => {
          isCreating.value = false
          Swal.fire({
            icon: 'error',
            title: 'Cập nhật thất bại',
            showConfirmButton: false,
            timer: 1500,
          })
        })
      } else {
        addBankAccount(formData)
          .then(res => {
            emit('created', res)
            Swal.fire(
              'Thành công!',
              'Thêm tài khoản thành công!',
              'success',
            )
            closeModal()
          }).catch(err => {
            const { response } = err
            if (response.status === 400) {
              const { message } = response.data
              Swal.fire(
                'Thất bại!',
                `${message}`,
                'error',
              )
            } else {
              Swal.fire(
                'Thất bại!',
                'Thêm tài khoản thất bại!',
                'error',
              )
            }
          }).finally(() => {
            isCreating.value = false
          })
      }
    }

    onMounted(() => {
      initBankMasterOptions()
    })

    return {
      qrUrl,
      modal,
      showModal,
      bankMaster,
      bankMasterOptions,
      bankAccount,
      createBankAccount,
      closeModal,
      refInputEl,
      previewEl,
      inputImageRenderer,
      isCreating,
      isUpdate,
      updateAccount,
      createAccount,

      required,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
