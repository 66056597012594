import * as moment from 'moment-timezone'

/**
 * Format datetime
 * @param dateTimeDb
 * @returns {string}
 */
export const formatDateTimeDb = dateTimeDb => moment(dateTimeDb)
  .format('DD/MM/YYYY HH:mm:ss')

export const _ = null
