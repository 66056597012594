import { ref } from '@vue/composition-api'
import store from '@/store'

export const useBankAccountFilter = () => {
  const search = ref(null)
  const dateFrom = ref('')
  const dateTo = ref('')
  const orderStatus = ref('')
  const orderStatusOptions = ref([
    { value: 'INACTIVE', text: 'Dừng hoạt động' },
    { value: 'ACTIVE', text: 'Đang hoạt động' },
    { value: 'LIMITED', text: 'Cảnh báo' },
    { value: 'ERROR', text: 'Đang gặp lỗi' },
  ])

  return {
    dateFrom,
    dateTo,
    orderStatus,
    orderStatusOptions,
    search,
  }
}

export const useBankAccountTransactions = () => {
  const search = ref(null)
  const dateFrom = ref('')
  const dateTo = ref('')
  const orderStatus = ref('')
  const orderStatusOptions = ref([
    { value: 'success', text: 'Đã khớp lệnh' },
    { value: 'timeout', text: 'Ngừng khớp lệnh' },
    { value: 'waiting', text: 'Chưa khớp lệnh' },
  ])
  const mapStatus = status => {
    switch (status) {
      case 'success':
        return 'Đã khớp lệnh'
      case 'timeout':
        return 'Ngừng khớp lệnh'
      case 'waiting':
        return 'Chưa khớp lệnh'
      default:
        return 'Chưa khớp lệnh'
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'success':
        return 'success'
      case 'timeout':
        return 'danger'
      case 'waiting':
        return 'warning'
      default:
        return 'warning'
    }
  }

  const fields = ref([
    '#',
    { key: 'historyTransaction.transId', label: 'MÃ GIAO DỊCH' },
    { key: 'bankAccount.name', label: 'NƠI NHẬN' },
    { key: 'bankAccount.accountNumber', label: 'SỐ TÀI KHOẢN' },
    { key: 'bankAccount.name', label: 'NGƯỜI NHẬN' },
    { key: 'content', label: 'NỘI DUNG' },
    { key: 'moneyReceive', label: 'SỐ TIỀN NHẬN' },
    { key: 'updatedAt', label: 'THỜI GIAN CẬP NHẬT' },
    { key: 'status', label: 'Trạng thái' },
  ])
  const items = ref([])

  const meta = ref({
    itemCount: 0,
    page: 1,
    pageCount: 0,
    take: 50,
  })

  const fetchBankAccountTransactions = (...arg) => store.dispatch('bank-manager/fetchBankAccountTransactions', ...arg)

  return {
    dateFrom,
    dateTo,
    orderStatus,
    orderStatusOptions,
    search,
    meta,
    fields,
    items,
    mapStatus,
    mapVariant,
    fetchBankAccountTransactions,
  }
}

export const useBankAccountCreate = () => {
  const bankMaster = ref('')
  const bankMasterOptions = ref([])
  const bankAccount = ref({
    name: '',
    accountNumber: '',
    accountName: '',
    password: '',
    otp: '',
    qrCode: '',
    moneyLimitPerDay: '',
    moneyLimitToParent: '',
    balancer: '',
    bankMasterId: '',
  })

  const fetchBankMaster = (...arg) => store.dispatch('bank-manager/fetchBankMaster', ...arg)
  const addBankAccount = (...arg) => store.dispatch('bank-manager/addBankAccount', ...arg)

  return {
    bankMaster,
    bankMasterOptions,
    bankAccount,
    fetchBankMaster,
    addBankAccount,
  }
}

export const useBankAccountFetch = () => {
  // table
  const fields = ref([
    '#',
    { key: 'name', label: 'Tên tài khoản' },
    { key: 'accountNumber', label: 'Số tài khoản' },
    { key: 'bankMaster.bankName', label: 'tên ngân hàng' },
    {
      key: 'moneyLimitPerDay',
      label: 'hạn mức ngày',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    {
      key: 'moneyLimitToParent',
      label: 'rút về tk cha',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    {
      key: 'moneyReceive',
      label: 'tổng nhận',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    { key: 'balancer', label: 'số dư hiện tại' },
    { key: 'createdAt', label: 'thời gian tạo' },
    { key: 'updatedAt', label: 'thời gian cập nhật' },
    {
      key: 'status',
      label: 'trạng thái',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'actions',
      label: 'hành động',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ])
  const items = ref([])

  // pagination
  const meta = ref({
    itemCount: 0,
    page: 1,
    pageCount: 0,
    take: 50,
  })

  const handleSearch = (...args) => store.dispatch('bank-manager/fetchBankAccount', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'ACTIVE':
        return 'Đang hoạt động'
      case 'LIMITED':
        return 'Cảnh báo'
      case 'INACTIVE':
        return 'Dừng hoạt động'
      case 'ERROR':
        return 'Đang gặp lỗi'
      case 'WAITING_FOR_LOGIN':
        return 'Chờ đăng nhập'
      default:
        return 'Đang gặp lỗi'
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'LIMITED':
        return 'warning'
      case 'ACTIVE':
        return 'success'
      case 'ERROR':
        return 'danger'
      case 'INACTIVE':
        return 'secondary'
      default:
        return 'secondary'
    }
  }

  return {
    fields,
    items,
    meta,

    handleSearch,
    mapStatus,
    mapVariant,
  }
}

export const useInputImageRenderer = (inputEl, callback) => {
  const inputImageRenderer = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        // eslint-disable-next-line no-param-reassign
        callback.value.qrCode = reader.result
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  return {
    inputImageRenderer,
  }
}

export const useBankAccountPayout = () => {
  // table
  const fields = ref([
    '#',
    { key: 'name', label: 'Tên tài khoản' },
    { key: 'accountNumber', label: 'Số tài khoản' },
    { key: 'bankMaster.bankName', label: 'tên ngân hàng' },
    {
      key: 'moneyLimitPerDay',
      label: 'hạn mức ngày',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    {
      key: 'moneyLimitToParent',
      label: 'hạn mức chuyển',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    {
      key: 'balancer',
      label: 'số dư hiện tại',
      thClass: 'text-center',
      tdClass: 'text-right',
    },
    { key: 'createdAt', label: 'thời gian tạo' },
    { key: 'updatedAt', label: 'thời gian cập nhật' },
    {
      key: 'status',
      label: 'trạng thái',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'actions',
      label: 'hành động',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ])
  const items = ref([])

  // pagination
  const meta = ref({
    itemCount: 0,
    page: 1,
    pageCount: 0,
    take: 50,
  })

  const handleSearch = (...args) => store.dispatch('bank-manager/fetchBankPayout', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'available':
        return 'Đang hoạt động'
      case 'waning':
        return 'Cảnh báo'
      case 'stopped':
        return 'Dừng hoạt động'
      case 'error':
        return 'Đang gặp lỗi'
      default:
        return 'Đang gặp lỗi'
    }
  }

  return {
    fields,
    items,
    meta,

    handleSearch,
    mapStatus,
  }
}

export const useBankAccountHistoryPayout = () => {
  const search = ref(null)
  const dateFrom = ref('')
  const dateTo = ref('')
  const orderStatus = ref('')
  const orderStatusOptions = ref([
    { value: 'success', text: 'Đã khớp lệnh' },
    { value: 'timeout', text: 'Ngừng khớp lệnh' },
    { value: 'waiting', text: 'Chưa khớp lệnh' },
  ])
  const mapStatus = status => {
    switch (status) {
      case 'success':
        return 'Đã khớp lệnh'
      case 'timeout':
        return 'Ngừng khớp lệnh'
      case 'waiting':
        return 'Chưa khớp lệnh'
      default:
        return 'Chưa khớp lệnh'
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'success':
        return 'success'
      case 'timeout':
        return 'danger'
      case 'waiting':
        return 'warning'
      default:
        return 'warning'
    }
  }

  const fields = ref([
    '#',
    { key: 'transId', label: 'MÃ GIAO DỊCH' },
    { key: 'bankAccount.name', label: 'NƠI NHẬN' },
    { key: 'bankAccount.accountNumber', label: 'SỐ TÀI KHOẢN' },
    { key: 'bankAccount.name', label: 'NGƯỜI NHẬN' },
    { key: 'content', label: 'NỘI DUNG' },
    { key: 'moneyReceive', label: 'SỐ TIỀN CHUYỂN' },
    { key: 'updatedAt', label: 'THỜI GIAN CẬP NHẬT' },
    {
      key: 'status',
      label: 'Trạng thái',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
  ])
  const items = ref([])

  const meta = ref({
    itemCount: 0,
    page: 1,
    pageCount: 0,
    take: 50,
  })

  const fetchBankAccountTransactions = (...arg) => store.dispatch('bank-manager/fetchBankAccountHistoryPayout', ...arg)

  return {
    dateFrom,
    dateTo,
    orderStatus,
    orderStatusOptions,
    search,
    meta,
    fields,
    items,
    mapStatus,
    mapVariant,
    fetchBankAccountTransactions,
  }
}

export const useBankAccountActions = () => {
  const activeBankAccount = (...args) => store.dispatch('bank-manager/activeBankAccount', ...args)
  const deActiveBankAccount = (...args) => store.dispatch('bank-manager/deActiveBankAccount', ...args)
  const deleteBankAccount = (...args) => store.dispatch('bank-manager/deleteBankAccount', ...args)
  const updateBankAccount = (...args) => store.dispatch('bank-manager/updateBankAccount', ...args)

  return {
    activeBankAccount,
    deActiveBankAccount,
    deleteBankAccount,
    updateBankAccount,
  }
}

export const useStatistic = () => {
  const statistic = ref({
    accountActive: 0,
    accountInactive: 0,
    accountWaning: 0,
    sumMoney: 0,
  })
  const fetchStatistic = (...args) => store.dispatch('bank-manager/getStatisticBankAccount', ...args)

  return {
    statistic,
    fetchStatistic,
  }
}
