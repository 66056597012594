<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <bank-static />
      <b-row>
        <b-col>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              placeholder="Tên tài khoản/số tài khoản/ngân hàng"
            />
          </b-input-group>
        </b-col>

        <b-col cols="2">
          <b-form-datepicker
            v-model="dateFrom"
            local="vn"
            placeholder="Từ ngày"
            selected-variant="primary"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>

        <b-col cols="2">
          <b-form-datepicker
            v-model="dateTo"
            local="vn"
            placeholder="Đến ngày"
            selected-variant="primary"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <v-select
              v-model="orderStatus"
              placeholder="Trạng thái"
              label="text"
              :options="orderStatusOptions"
              :reduce="status => status.value"
            />
          </b-form-group>
        </b-col>

        <b-col cols="auto">
          <b-button
            variant="primary"
            class="mr-1"
            @click="fetchBankAccount"
          >
            Tìm kiếm
          </b-button>
        </b-col>

        <b-col cols="auto">
          <b-button
            variant="outline-primary"
            @click="createAccountModal.createAccount()"
          >
            Thêm tài khoản
          </b-button>
        </b-col>
      </b-row>

      <b-table
        small
        :fields="fields"
        :items="items"
        responsive="sm"
        class="mt-2"
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #empty="scope">
          <b-row>
            <b-col>
              <b-alert
                show
                variant="info"
                class="text-center"
              >
                Không có dữ liệu
              </b-alert>
            </b-col>
          </b-row>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="mapVariant(data.value)">
            {{ mapStatus(data.value) }}
          </b-badge>
        </template>

        <template #cell(createdAt)="data">
          <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
        </template>

        <template #cell(updatedAt)="data">
          <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
        </template>

        <template #cell(moneyLimitPerDay)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>

        <template #cell(moneyLimitToParent)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>

        <template #cell(totalMoneyIn)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>

        <template #cell(balancer)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>

        <template #cell(moneyReceive)="data">
          <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
        </template>

        <template #cell(actions)="data">
          <span>
            <b-dropdown
              right
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="ArrowRightCircleIcon"
                  size="20"
                  class="text-body align-middle"
                />
              </template>
              <b-dropdown-item
                v-if="data.item.status !== 'ACTIVE'"
                @click="handleActiveBankAccount(data.item)"
              >
                <span>Kích hoạt tài khoản</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status !== 'INACTIVE'"
                @click="handleDeActiveBankAccount(data.item)"
              >
                <span>Dừng tài khoản</span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleDeleteBankAccount(data.item)">
                <span>Xoá tài khoản</span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleUpdateBankAccount(data.item)">
                <span>Sửa tài khoản</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>

      <b-row>
        <b-col class="d-flex justify-content-md-end">
          <b-pagination
            v-model="meta.page"
            :total-rows="meta.itemCount"
            :per-page="meta.take"
            first-text="Trang đầu"
            prev-text="Trang trước"
            next-text="Trang tiếp"
            last-text="Trang cuối"
            align="left"
            @change="changePage"
          />
        </b-col>
      </b-row>

      <create-account-modal
        ref="createAccountModal"
        @created="fetchBankAccount"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BAlert,
  BDropdown,
  BDropdownItem,
  BBadge,
  BOverlay,
} from 'bootstrap-vue'
import BankStatic from '@/views/bank/BankStatic.vue'
import vSelect from 'vue-select'
import { formatDateTimeDb } from '@/libs/timezone'
import { onMounted, ref } from '@vue/composition-api'
import CreateAccountModal from '@/views/bank/CreateAccountModal.vue'
import Swal from 'sweetalert2'
import { useBankAccountFilter, useBankAccountFetch, useBankAccountActions } from './useBank'

export default {
  name: 'PayinBankAccount',
  components: {
    CreateAccountModal,
    BankStatic,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BAlert,
    BDropdown,
    BDropdownItem,
    BBadge,
    BOverlay,
  },
  setup() {
    const isLoading = ref(false)
    const createAccountModal = ref(null)

    const {
      search,
      dateFrom,
      dateTo,
      orderStatus,
      orderStatusOptions,
    } = useBankAccountFilter()

    const {
      handleSearch,
      fields,
      items,
      mapStatus,
      meta,
      mapVariant,
    } = useBankAccountFetch()

    const {
      activeBankAccount,
      deActiveBankAccount,
      deleteBankAccount,
    } = useBankAccountActions()

    const fetchBankAccount = () => {
      handleSearch({
        search: search.value,
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        orderStatus: orderStatus.value,
        ...meta.value,
      }).then(response => {
        items.value = response.data
        meta.value = response.meta
      })
    }

    const changePage = page => {
      meta.value.page = page
      fetchBankAccount()
    }

    const handleActiveBankAccount = item => {
      isLoading.value = true
      activeBankAccount({ id: item.id })
        .then(response => {
          if (response.code === 'FAIL') {
            Swal.fire({
              icon: 'error',
              title: 'Kích hoạt tài khoản thất bại lỗi đăng nhập',
              showConfirmButton: false,
              timer: 1000 * 5,
            })
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Kích hoạt tài khoản thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Kích hoạt tài khoản thất bại lỗi đăng nhập',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .finally(() => {
          isLoading.value = false
          fetchBankAccount()
        })
    }

    const handleDeleteBankAccount = item => {
      deleteBankAccount({ id: item.id }).then(() => {
        Swal.fire({
          title: 'Xoá tài khoản thành công',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
        fetchBankAccount()
      })
    }

    const handleDeActiveBankAccount = item => {
      isLoading.value = true
      deActiveBankAccount({ id: item.id }).then(() => {
        Swal.fire({
          title: 'Dừng tài khoản thành công',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
        fetchBankAccount()
      }).finally(() => {
        isLoading.value = false
      })
    }

    const handleUpdateBankAccount = item => {
      createAccountModal.value.updateAccount(item)
    }

    onMounted(() => {
      fetchBankAccount()
    })

    return {
      isLoading,
      search,
      dateFrom,
      dateTo,
      orderStatus,
      orderStatusOptions,
      formatDateTimeDb,

      mapStatus,
      fetchBankAccount,
      fields,
      items,
      meta,
      changePage,

      createAccountModal,
      // actions
      handleActiveBankAccount,
      handleDeActiveBankAccount,
      handleDeleteBankAccount,
      handleUpdateBankAccount,
      mapVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
